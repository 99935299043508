// Stylesheets
import './tapWall.css';
import './tapWallTablet.css';
import './tapWallMobile.css';

// React
import {useEffect, useState} from "react";

// Firebase
import {Beer, beerConverter} from "../../objects";
import {collection, getDocs, orderBy, query} from "firebase/firestore";
import {db} from "../../firebase";

// Walls
import { DesktopWall, MobileWall, TabletWall } from './walls';

// Components
import { useWindowDimensions } from "../../func";

const TapWall = (props) => {
  let beerCard = props.beerCard;
  let setBeerCard = props.setBeerCard;

  const { height, width } = useWindowDimensions();

  let [beers, setBeers] = useState(Array(10).fill(new Beer('','', '', '', '', '', '', '', '', '', '')));
  let [wall, setWall] = useState(<DesktopWall beers={beers} />)

  const getBeers = async () => {
    let newBeers = []
    const q = await query(collection(db, "beersOnTap"), orderBy("position"));
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
      let beer = beerConverter.fromFirestore(doc);
      beer.name = beer.name.replace("'", '')  // No apostrophe in font so remove it
      newBeers.push(beer)
    });
    if (newBeers.length < 10) {
      for (let i = newBeers.length; i < 10; i++) {
        newBeers.push(new Beer('','', '', '', '', '', '', '', '', '', ''))
      }
    }
    setBeers(newBeers);
  }

  // Only get beer on first load, as long as array is empty
  useEffect(() => {
    if (beers[0].name === '' && beers[9].name === '') {
      getBeers().then(r => {

      })
    }
  }, []);

  const whichWall = () => {
    if (width > 1400) {
      setWall(<DesktopWall beers={beers} beerCard={beerCard} setBeerCard={setBeerCard} />)
    } else if (width <= 1400 && width > 640) {
      setWall(<TabletWall beers={beers} beerCard={beerCard} setBeerCard={setBeerCard} />)
    } else {
      setWall(<MobileWall beers={beers} beerCard={beerCard} setBeerCard={setBeerCard} />)
    }
  }

  useEffect(() => {
    whichWall()
  }, [beers, width]);

  return (
    <div ref={props.refProp} className={'homeContainer'}>
      {wall}
    </div>
  )
}

export default TapWall