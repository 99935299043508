import './reviews.css';
import './reviewsMobile.css';
import './reviewsTablet.css';

import {useEffect, useRef, useState} from "react";
import ReactStars from "react-stars/dist/react-stars";
import useWindowDimensions from "../../func/getWindowDimensions";

// import {CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Dot} from 'pure-react-carousel';
// import 'pure-react-carousel/dist/react-carousel.es.css';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BetterReviews = (props) => {
  const { height, width } = useWindowDimensions();
  let [translate, setTranslate] = useState(0);

  const delay = 6000;
  const timeoutRef = useRef(null);
  const [index, setIndex] = useState(0);
  let [reviews, setReviews] = useState([])

  let bannedReviews = [
    'What a cool place, literally, it was cold.  The jackets never came off.  The beer and beer selection was on point for such a small place.  Also a nice selection of beers to go.  Also very convenient as it was just down the street from our hotel.',
    "First time here but sadly it was the last bar of the day and my memory is a little rusty. I remember the beer selection being good and the pint I had was very nice, just don't ask me what it was! Will go back again if only to check it out with a clearer head.",
    "Situated under the railway archers near to the life science centre, a little off the main drag, it could easily be missed."
  ]

  const getReviews = () => {
    fetch(`https://places.googleapis.com/v1/places/ChIJXaFxEAFxfkgR0G7q7KQNVd8?fields=id,displayName,reviews&key=AIzaSyA7UjW4zL9uo0SiaD01FBKjZV22Cg4GY0M`, {
      headers: {
        "Content-Type": "application/json"
      },
      method: "GET"
    }).then(r => {
      return r.json()
    }).then(json => {
      if (json.reviews !== undefined && json.reviews.length > 0) {
        let newReviews = []
        for (let i = 0; i < json.reviews.length; i++) {
          if (!bannedReviews.includes(json.reviews[i].originalText.text)) {
            newReviews.push(json.reviews[i])
          }
        }
        setReviews(newReviews)
      }
    })
  }

  useEffect(() => {
    if (reviews.length < 5) {
      getReviews()
    }
  }, []);

  const settings = {
    arrows: width >= 640,
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    centerMode: width >= 640,
  };

  return (
    <div className={"reviewsContainer"}>
      <Slider {...settings}>
        {reviews.map((review, i) => (
          <div key={`slide${review.authorAttribution.displayName}`}>
            <div className={'reviewCard'} key={i}>
              <div className={'reviewHeader'}>
                <img onClick={() => {
                  window.open(review.authorAttribution.uri, "_blank")
                }} className={'reviewProfilePic'} src={review.authorAttribution.photoUri}/>
                <div className={'reviewProfileInfo'}>
                  <div className={'reviewText reviewProfileName'}>{review.authorAttribution.displayName}</div>
                  <div className={'reviewText reviewPublishTime'}>{review.relativePublishTimeDescription}</div>
                </div>
              </div>
              <div className={'reviewFooter'}>
                <ReactStars className={'reviewStars'} count={review.rating} value={review.rating} edit={false} size={24} color2={'#ffd700'}/>
                <div className={'reviewText text'}>{review.originalText.text}</div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default BetterReviews;