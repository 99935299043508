import './printPage.css'
import {LogoBlack} from "../../../../imgs";
import {forwardRef} from "react";

const PrintPage = forwardRef((props, ref) => {
  let {team} = props;

  return (
    <div className={'fullPage'} ref={ref}
         style={{width: '210mm', height: '297mm'}}>
      <div className={'pageHalf half1'}>

      </div>
      <div className={'pageHalf half2'}>
        <img className={'pageLogo'} src={LogoBlack}/>
        <div>
          reserved for {team.name.toLowerCase()}
        </div>
        <div>
          {Array.isArray(team.size) ? team.size.join(', ') : team.size} people - {team.time}
        </div>
      </div>
    </div>
  );
});

export default PrintPage;