export const findTwoTablesOfX = (tables, number) => {
  const availableTables = tables.filter(t => t.capacity === number && t.currentSize === 0);
  return availableTables.length >= 2 ? availableTables.slice(0, 2) : [];
};

export const checkAllTeamsContained = (bookings, tables) => {
  const allTableTeamIds = tables.flatMap(table => table.teams.map(team => team.teamId));
  return bookings.filter(team => !allTableTeamIds.includes(team.teamId));
};


export function assignTables(tables, modifiedTables, teams) {
  let noSeats = [];
  let emptyTables = [];
  let printBookings = [];
  let totalPeople = 0;

  let currentTables = tables;
  if (modifiedTables !== null) {
    currentTables = JSON.parse(JSON.stringify(modifiedTables))
  }

  teams.sort((a, b) => a.id - b.id); // First team that booked first (ID 1 first)
  currentTables.sort((b, a) => b.capacity - a.capacity); // Smallest first

  function assignTable(table, team, printBookings) {
    table.currentSize += team.size;
    table.teams.push(team)
    totalPeople += team.size
    printBookings.push(team);
  }

  for (const team of teams) {
    if (Array.isArray(team.size)) {
      team.size = team.size.reduce((a, b) => a + b, 0);
    }

    for (const table of currentTables) {
      if (table.capacity !== table.currentSize) { // Check table is not already full
        if (table.currentSize + team.size <= table.capacity) { // If the team size can fit on the table
          if (team.size >= 6) {
            if (table.capacity === 6) { // Try assign team of 6 to table of 6
              assignTable(table, team, printBookings);
              break;
            } else if (table.capacity > 6) {
              assignTable(table, team, printBookings);
              break;
            }
          } else if (team.size === 5) {
            if (table.capacity === 5 || table.capacity === 6) { // Try put table of 5 on 5 first.
              assignTable(table, team, printBookings);
              break;
            } else if (table.capacity > 6) {
              assignTable(table, team, printBookings);
              break;
            }
          } else if (team.size === 4) {
            if (table.capacity === 8 && (table.currentSize + team.size) <= 6) {
              assignTable(table, team, printBookings);
              break;
            } else {
              let tablesOfTwo = findTwoTablesOfX(currentTables, 2);
              if (tablesOfTwo !== null && tablesOfTwo.length >= 2) {
                for (const subTable of tablesOfTwo) {
                  subTable.currentSize = 2;
                  subTable.teams.push(team);
                }
                totalPeople += team.size
                break;
              } else {
                if (table.capacity >= 4 && (table.currentSize + team.size) <= 6) {
                  assignTable(table, team, printBookings);
                  break;
                }
              }
            }
          } else if (team.size === 3) {
            if (table.capacity === 3) {
              assignTable(table, team, printBookings);
              break;
            } else {
              if (table.capacity === 8 && table.currentSize + team.size <= 6) {
                assignTable(table, team, printBookings);
                break;
              }
            }
          } else {  // Team is less than or equal to 2
            if (table.capacity <= 2) {
              assignTable(table, team, printBookings);
              break;
            } else {
              if (table.capacity === 8 && table.currentSize + team.size <= 6) {
                assignTable(table, team, printBookings);
                break;
              }
            }
          }
        } else {
          if (team.size > 8) {
            let size = team.size;
            let tablesOfEight = findTwoTablesOfX(currentTables, 8);
            if (tablesOfEight !== null && tablesOfEight.length >= 2) {
              let teamSize = team.size;
              for (const subTable of tablesOfEight) {
                teamSize = teamSize - 8 <= 0 ? team.size - teamSize : teamSize - 8
                subTable.currentSize += teamSize;
                subTable.teams.push(team);
                printBookings.push(team);
              }
              totalPeople += team.size
              break;
            }
            // if (table.capacity === 8) { // Try assign team of 8 to table of 8
            //   assignTable(table, team, printBookings);
            //   break;
            // }
          } else if (team.size === 4) {
            if (table.capacity === 8 && (table.currentSize + team.size) <= table.capacity) {
              table.currentSize += team.size;
              totalPeople += team.size
              break;
            } else {
              let tablesOfTwo = findTwoTablesOfX(currentTables, 2);
              if (tablesOfTwo !== null && tablesOfTwo.length >= 2) {
                for (const subTable of tablesOfTwo) {
                  subTable.currentSize = 2;
                  subTable.teams.push(team);
                  printBookings.push(team);
                }
                totalPeople += team.size
                break;
              }
            }
          }
        }
      }
    }
  }

  // Check if no team was seated
  noSeats = checkAllTeamsContained(teams, currentTables)
  emptyTables = currentTables.filter(table => table.teams.length === 0);
  let emptySpaces = tables.flat().map(table => table.capacity).reduce((a, b) => a + b, 0) - currentTables.flat().map(table => table.currentSize).reduce((a, b) => a + b, 0)

  return {assignedTables: currentTables, printBookings, noSeats, emptySpaces, noTables: emptyTables, total: totalPeople}
}