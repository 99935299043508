import './quizBookingInfo.css';
import './quizBookingInfoMobile.css';
import './quizBookingInfoTablet.css';

import {useEffect, useState} from "react";
import {getNext10WednesdaysWithSoldOut, updateArrayAtIndex} from "../../../func";
import {Booking} from "../../../objects";
import {Timestamp} from "firebase/firestore"
import {findTwoTablesOfX} from "../../../pages/updateBooking/tableFuncs";
import {getLayout} from "../../../pages/updateBooking/firebaseFuncs";

const QuizBookingInfo = (props) => {
  let {translate, setPageNo, bookingInfo, setBookingInfo, width, update, bookingId} = props;

  const [wednesdays, setWednesdays] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [people, setPeople] = useState([0]);
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");

  const [soldOut, setSoldOut] = useState(false);
  const [layout, setLayout] = useState(null);
  const [tablesRemaining, setTablesRemaining] = useState([])
  const [largestEmptyCapacity, setLargestEmptyCapacity] = useState(null);

  // Current Booking (Update)
  const [currentTeamSize, setCurrentTeamSize] = useState(null);
  const [currentNumberOfTeams, setCurrentNumberOfTeams] = useState(null);
  const [currentEmptySpaces, setCurrentEmptySpaces] = useState(null);

  useEffect(() => {
    if (layout !== null) {
      setTablesRemaining(layout.emptyTables);
      if (update && bookingInfo && soldOut) {
        if (currentTeamSize === null && currentEmptySpaces === null) {
          setPeople(bookingInfo.size)
          let size = bookingInfo.size.reduce((a, b) => a + b, 0);
          setCurrentTeamSize(size);
          setCurrentNumberOfTeams(bookingInfo.size.length);

          let currentTables = layout.assignedTables.filter(table => table.teams.find(team => team.bookingId === bookingId));
          let emptySpaces = currentTables.reduce((sum, table) => {
            return sum + (table.capacity - table.currentSize);
          }, 0)
          setCurrentEmptySpaces(emptySpaces);
          setLargestEmptyCapacity(emptySpaces + size)

          if ((emptySpaces + size) > 6) {
            let maxNumberOfTeams = Math.ceil(((emptySpaces + size) / 6))
            setCurrentNumberOfTeams(maxNumberOfTeams)
          }
        }
      } else {
        setLargestEmptyCapacity(layout.emptyTables.reduce((maxCapacity, tableObj) => {
          return (tableObj.currentSize === 0 && tableObj.capacity > maxCapacity)
            ? tableObj.capacity > 6
              ? 6 : tableObj.capacity < 4
                ? findTwoTablesOfX(layout.emptyTables, 2).length >= 2
                  ? 4 : tableObj.capacity : tableObj.capacity
            : maxCapacity;
        }, 0));
      }
    } else if (largestEmptyCapacity === null) {
      setLargestEmptyCapacity(6)
    }
  }, [update, layout]);

  useEffect(() => {
    if (date !== "") {
      getLayout(date).then((doc) => {
        setLayout(doc);
      })
    }
  }, [date]);

  useEffect(() => {
    if (wednesdays.length > 0) {
      setDate(wednesdays[0]);
    }
  }, [wednesdays]);

  useEffect(() => {
    if (date.includes("[SOLD OUT]")) {
      setSoldOut(true)
    } else {
      setSoldOut(false)
    }
  }, [date]);

  useEffect(() => {
    if (wednesdays.length === 0) {
      getNext10WednesdaysWithSoldOut().then(newWeds => {
        setWednesdays(newWeds);
      })
    }
  }, [wednesdays]);

  useEffect(() => {
    if (bookingInfo) {
      setName(bookingInfo.name);
      setEmail(bookingInfo.email);
      setTime(bookingInfo.time);
      setPeople(bookingInfo.size)
      setDate(bookingInfo.date)
    }
  }, [bookingInfo]);

  const teamSizeSelect = (id) => {
    return (
      <>
        {people.length > 1 ? <div className={'teamTitle'}>Team {id + 1}</div> : <></>}
        <select key={`select-${id}`} className={'quizSelect'} id={'time'} onChange={(event) => {
          updateArrayAtIndex(people, setPeople, id, parseInt(event.target.value));
        }} value={people[id]}>
          <option value={0}>Team size
            ({largestEmptyCapacity >= 6 || (largestEmptyCapacity <= 0 && tablesRemaining.length <= 0) ? `Max 6 people per team` : !update && soldOut ? `Only ${tablesRemaining.length > 1 ? `tables` : `1 table`} of ${largestEmptyCapacity} left${tablesRemaining.length < 2 ? `!` : ``}` : `Cannot exceed original booking`})
          </option>
          {Array.from({
              length:
                update && id + 1 === currentNumberOfTeams && id !== 0
                  ?
                  largestEmptyCapacity - (people.length > 2 ? people.slice(0, -1).reduce((a, b) => a + b, 0) : people[0])
                  :
                  largestEmptyCapacity > 6
                    ? 6 : largestEmptyCapacity
            }, (v, k) => k + 1
          ).map((index) => {
            return (
              <option key={`option-${index}`} value={index}>{index}</option>
            )
          })}
        </select>
      </>
    )
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <div style={{translate: translate}} className={'quizBookingSection quizBookingInfoContainer'}>
      <div className={'quizFieldsContainer'}>
        {!update ? <h1>BOOK A QUIZ TABLE</h1> : <h1>UPDATE BOOKING</h1>}
        {!soldOut || update ? <>
          <input id={'fname'} onChange={(event) => {
            setName(event.target.value);
          }} placeholder={"Name for booking"} value={name} type={'fname'} autoComplete={'given-name'}></input>
          <input id={'email'} onChange={(event) => {
            setEmail(event.target.value);
          }} placeholder={"Email (For booking confirmation)"} value={email} type={'email'}
                 autoComplete={'email'}></input>
          {teamSizeSelect(0)}
          {people.length > 1 ? people.map((item, index) => {
            return (
              index > 0 ? teamSizeSelect(index) : <></>
            )
          }) : <></>}
          {
            (largestEmptyCapacity >= 6 && !soldOut) || (update && currentNumberOfTeams > 1)
              ?
              <div className={'bookAnotherContainer'}>
                <span>Need to book another team?</span>
                <button
                  disabled={
                    people.length > 3 || (update && currentNumberOfTeams === people.length && soldOut)
                  }
                  className={'adminBtn bookAnotherBtn bookAnotherPlus'} onClick={() => {
                  if (people.length < 4) {
                    setPeople(people => ([...people, 0]))
                  }
                }}>+
                </button>
                <button disabled={people.length < 2} className={'adminBtn bookAnotherBtn bookAnotherMinus'}
                        onClick={() => {
                          if (people.length > 1) {
                            setPeople(people => people.slice(0, people.length - 1));
                          }
                        }}>-
                </button>
              </div> : <></>}
          <select id={'time'} onChange={(event) => {
            setTime(event.target.value);
          }} value={time}>
            <option value={""}>Time for table (6:45pm - 7:45pm)</option>
            <option value={"6:45pm"}>6:45pm</option>
            <option value={"7:00pm"}>7:00pm</option>
            <option value={"7:15pm"}>7:15pm</option>
            <option value={"7:30pm"}>7:30pm</option>
            <option value={"7:45pm"}>7:45pm</option>
          </select>
        </> : <>
          <div className={'soldOutMessage'}>
            The quiz this week is <b>sold out</b>! However, feel free to <b>choose another week</b> to get your table
            booking in early.
          </div>
          <br/>
        </>}

        <select id={'wednesdays'} onChange={(event) => {
          setDate(event.target.value);
        }} value={date}>
          {wednesdays.map((date, index) => (
            <option key={index} value={date}>
              {date}
            </option>
          ))}
        </select>
      </div>

      <div className={'quizBtnContainer'}>
        <button onClick={() => {
          let valid = false;

          while (!valid) {
            if (name === "") {
              alert("Please enter your name")
              break;
            }
            if (time === "") {
              alert("Please select a time for your table")
              break;
            }
            if (!validateEmail(email)) {
              alert("Please enter a valid email address")
              break;
            }
            if (people.filter(team => team === 0).length > 0) {
              alert("Make sure you have selected a size for your team(s)")
              break;
            }
            console.log(currentTeamSize, currentEmptySpaces, (people.reduce((a, b) => a + b, 0)), soldOut)
            if ((people.reduce((a, b) => a + b, 0) > currentTeamSize && soldOut) && ((people.reduce((a, b) => a + b, 0) > (currentTeamSize + currentEmptySpaces)) && soldOut)) {
              alert("The quiz has sold out! You can only change your team size to less than you originally booked for.")
              break;
            }
            valid = true;
          }

          if (valid) {
            setBookingInfo(new Booking(name, email, people, date, time, new Date(), Timestamp.fromDate(new Date(Date.parse(wednesdays[2])))));
            setPageNo(2)
          } // TODO: If authenticated then just update without email...

        }} className={'adminBtn quizBtn'} disabled={soldOut && !update}>
          {soldOut && !update ? "SOLD OUT" : `${!update ? `SEND` : `UPDATE`} BOOKING`}
        </button>
        {!update ?
          <div className={'contactQuiz'}>Contact <a href={"mailto:info@mosaictap.com"}>info@mosaictap.com</a> for
            special
            requests
          </div> : <></>}
      </div>
    </div>
  )
}

export default QuizBookingInfo;