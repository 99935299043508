export class Team {
  constructor(bookingId, teamId, name, size, date, time, email) {
    this.bookingId = bookingId;
    this.teamId = teamId;
    this.name = name;
    this.size = size;
    this.date = date;
    this.time = time;
    this.email = email;
  }
}