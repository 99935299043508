// React
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";

// Firebase
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {addDoc, collection, doc, getDocs, orderBy, query, setDoc, where} from "firebase/firestore";
import {db} from "../../../firebase";

// Stylesheets
import './quizTables.css';
import './quizTablesMobile.css';

// Components and Objects
import FloorPlan from './floorPlan/FloorPlan';
import {
  TableObj,
  Booking,
  bookingConverter
} from "../../../objects";

// Functions
import {getNext10WednesdaysWithSoldOut, updateArrayAtIndex} from "../../../func";

// Printing
import PrintPage from "./printPage/PrintPage";
import {useReactToPrint} from "react-to-print";
import {assignTables} from "../../updateBooking/tableFuncs";
import {getCurrentBookings, getLayout, saveAndUploadTableLayout} from "../../updateBooking/firebaseFuncs";
import {tables} from "../../updateBooking/initTables";


const QuizTables = (props) => {
  let navigate = useNavigate()

  // Printing
  const [printBookings, setPrintBookings] = useState([]);
  const [teamToPrint, setTeamToPrint] = useState(null)
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setTeamToPrint(null)
    }
  });

  // Auth
  let {user, setUser} = props;
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(user);
    } else {
      navigate('/admin')
    }
  });

  // Manual Booking
  const [name, setName] = useState("");
  const [people, setPeople] = useState(0);
  const [time, setTime] = useState("");

  // Date selector
  const [wednesdays, setWednesdays] = useState([]);
  const [date, setDate] = useState("");

  // Tables
  const [modifiedTables, setModifiedTables] = useState(tables)
  const [assignedTables, setAssignedTables] = useState(tables);

  // Booking Info and Layout
  const [bookings, setBookings] = useState([]);
  const [cannotBeSeated, setCannotBeSeated] = useState([])
  const [emptyTables, setEmptyTables] = useState([])
  const [totalPeople, setTotalPeople] = useState([]);
  const [emptySpaces, setEmptySpaces] = useState(0)
  const [dbLayout, setDbLayout] = useState(null);

  useEffect(() => {
    if (wednesdays.length > 0) {
      setDate(wednesdays[0].split(" [SOLD OUT]")[0]);
    }
  }, [wednesdays]);

  useEffect(() => {
    if (wednesdays.length === 0) {
      getNext10WednesdaysWithSoldOut().then(newWeds => {
        setWednesdays(newWeds);
      })
    }
  }, [wednesdays]);

  useEffect(() => {
    if (date !== "") {
      setAssignedTables(tables)
      // setBookings(testTeams4)
      getCurrentBookings(date).then((bookings) => {
        setBookings(bookings);
        calculateAssignments(bookings)

        getLayout(date).then((layout) => {
          if (layout !== null) {
            setAssignedTables(layout.assignedTables)
          }
        })
      })
    }
  }, [date]);

  useEffect(() => {
    calculateAssignments(bookings)
  }, [modifiedTables])

  function calculateAssignments(bookings) {
    if (bookings && bookings.length > 0) {
      setPrintBookings([])
      const {
        assignedTables,
        printBookings,
        noSeats,
        emptySpaces,
        noTables,
        total
      } = assignTables(tables, modifiedTables, bookings);
      setAssignedTables(assignedTables)
      setPrintBookings(printBookings);
      setCannotBeSeated(noSeats)
      setEmptySpaces(emptySpaces)
      setEmptyTables(noTables)
      setTotalPeople(total);
    }
  }

  const sendBooking = async () => {
    let booking = new Booking(name, "Manual Booking", [people], date, time, new Date(), Date.parse(wednesdays[2]));
    const ref = collection(db, "bookings").withConverter(bookingConverter)  // E.g. "bookings/Name-Sep18" `${name}-${date.split(' ')[1]}${date.split(' ')[2]}}`
    await addDoc(ref, booking).then(async (docRef) => {
      alert("Added booking");
      getCurrentBookings(date).then((bookings) => {
        setBookings(bookings);
      })
    })
  }

  useEffect(() => {
    if (teamToPrint !== null) {
      handlePrint()
    }
  }, [teamToPrint]);

  return (
    <>
      {user !== null ?
        <div className={'quizTablesOuterContainer'}>
          <h1>QUIZ LAYOUT</h1>
          <select id={'wednesdays'} onChange={(event) => {
            setDate(event.target.value.split(" [SOLD OUT]")[0]);
          }} value={date}>
            {wednesdays.map((date, index) => (
              <option key={index} value={date}>
                {date}
              </option>
            ))}
          </select>
          <div className={'quizTablesContainer'}>
            <FloorPlan tables={assignedTables} setTables={setAssignedTables}/>
            <div className={'quizTablesInfoContainer'}>
              <h2>Bookings:</h2>
              <ul>
                {bookings.map((booking) => (
                  <li key={`${booking.bookingId}-${booking.name}`}>
                    {booking.name} (Size: {booking.size}) ({booking.time})
                    <button onClick={() => {
                      navigate(`/booking/${booking.bookingId}/${booking.email}`)
                    }}>🔍</button>
                    <button onClick={() => {
                      setTeamToPrint(booking)
                    }}>🖨️
                    </button>
                  </li>
                ))}
                <button className={'adminBtn'} onClick={() => {
                  setTeamToPrint(printBookings)
                }}>PRINT ALL LABELS (USE CHROME)
                </button>
              </ul>

              <h2>Table Assignments:</h2>
              <ul>
                {/* Sort assignments by table ID before rendering */}
                {assignedTables.sort((a, b) => {
                  const tableAId = a.id;
                  const tableBId = b.id;
                  return tableAId - tableBId;
                }).map((table, index) => (
                  <li key={table.id}>
                    <span>Table {table.id} ({table.capacity}) has teams {table.teams.map((team, index) => (`${team.name} (${team.size}) `))}</span>
                    {table.id === 10 || table.id === 11 || table.id === 12 || table.id === 13 ? <span><button
                      className={'swapTeamBtn'} style={{fontSize: '12px'}} onClick={() => {
                      let newTable = table
                      if (newTable.capacity === 2) {
                        if (table.id !== 13) {
                          newTable.capacity = 4;
                        } else {
                          newTable.capacity = 5;
                        }
                      } else {
                        newTable.capacity = 2;
                      }
                      newTable.teams = [];
                      newTable.currentSize = 0;


                      const updatedArray = modifiedTables.map((item, i) => {
                        if (i === index) {
                          return newTable;
                        } else {
                          return item;
                        }
                      });

                      setModifiedTables(updatedArray);
                    }}>{table.capacity === 2 ? `⬆️` : `⬇️`}</button></span> : <></>}
                  </li>
                ))}
                <button className={'swapTeamBtn'} style={{fontSize: '12px'}} onClick={() => {
                  if (modifiedTables.length === 13) {
                    setModifiedTables([...modifiedTables, new TableObj(14, 6)])
                  } else {
                    setModifiedTables(modifiedTables.filter(table => table.id !== 14));
                  }
                }}>{modifiedTables.length === 13 ? `➕` : `➖`}</button>
              </ul>

              <h2>{cannotBeSeated.length > 0 ? `Cannot Be Seated:` : <></>}</h2>
              <ul>
                {cannotBeSeated.map((team, index) => {
                  return (
                    <li key={team.name}>
                      {team.name} (Size: {team.size})
                    </li>
                  )
                })}
              </ul>

              <h2>{emptyTables.length > 0 ? `Empty Tables:` : <></>}</h2>
              <ul>
                {emptyTables.sort((a, b) => {
                  const tableAId = a.id;
                  const tableBId = b.id;
                  return tableAId - tableBId;
                }).map((table, index) => {
                  return (
                    <li key={table.id}>
                      ID: {table.id} (Size: {table.capacity})
                    </li>
                  )
                })}
              </ul>

              <h2>{emptySpaces > 0 ? `Empty Spaces: ${emptySpaces}` : <></>}</h2>
              <h2>Total People: {totalPeople}</h2>
            </div>
          </div>
          <button className={'adminBtn'} onClick={() => {
            calculateAssignments(bookings)
          }}>CALCULATE TABLE ASSIGNMENTS
          </button>
          <button className={'adminBtn'} onClick={() => {
            saveAndUploadTableLayout(date, assignedTables, emptyTables, emptySpaces, totalPeople)
          }}>SAVE LAYOUT
          </button>

          <br/><br/><br/><br/>
          <h2>Add manual booking</h2>
          <div>
            <input onChange={(event) => {
              setName(event.target.value);
            }} placeholder={"Name for booking"} value={name} type={'fname'}></input>
            <select className={'quizSelect'} id={'time'} onChange={(event) => {
              setPeople(parseInt(event.target.value))
            }} value={people}>
              <option value={0}>Team size</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
              <option value={11}>11</option>
              <option value={12}>12</option>
              <option value={13}>13</option>
              <option value={14}>14</option>
              <option value={15}>15</option>
              <option value={16}>16</option>
            </select>
            <select id={'time'} onChange={(event) => {
              setTime(event.target.value);
            }} value={time}>
              <option value={""}>Table time</option>
              <option value={"6:45pm"}>6:45pm</option>
              <option value={"7:00pm"}>7:00pm</option>
              <option value={"7:15pm"}>7:15pm</option>
              <option value={"7:30pm"}>7:30pm</option>
              <option value={"7:45pm"}>7:45pm</option>
            </select>
            <button onClick={() => {
              sendBooking()
            }}>SEND BOOKING
            </button>
            <br/><br/>
          </div>
          {!Array.isArray(teamToPrint) && teamToPrint !== null ?
            <PrintPage ref={componentRef} team={teamToPrint}/> : <></>}
          {Array.isArray(teamToPrint) && teamToPrint !== null ? <div ref={componentRef}>
            {teamToPrint.map((team) => {
              return (
                <PrintPage team={team}/>
              )
            })}
          </div> : <></>}
        </div>
        : <></>}
    </>
  );
};

export default QuizTables;