// Stylesheets
import './updateQuiz.css';

// FIrebase
import {doc, setDoc} from "firebase/firestore";
import {db} from "../../../firebase";
import {getAuth, onAuthStateChanged} from "firebase/auth";

// React
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

// Objects
import {Quiz, quizConverter} from "../../../objects";

// Functions
import {getNext10WednesdaysWithSoldOut} from "../../../func";

const UpdateQuiz = (props) => {
  let navigate = useNavigate();
  let user = props.user;
  let setUser = props.setUser;
  let auth = getAuth();

  const [wednesdays, setWednesdays] = useState([]);
  const [date, setDate] = useState("");
  const [upload, setUpload] = useState(false);

  const uploadToFirestore = async (quiz) => {
    const ref = doc(db, "quiz", quiz.date).withConverter(quizConverter)
    await setDoc(ref, quiz).then(() => {

    })
  }

  useEffect(() => {
    if (date !== "" && date !== undefined && upload['upload']) {
      if ((!date.includes("[SOLD OUT]") && upload['soldOut']) || (date.includes("[SOLD OUT]") && !upload['soldOut'])) {
        let quiz = new Quiz(date.replace(" [SOLD OUT]", ""), upload['soldOut'])
        uploadToFirestore(quiz).then(r => window.location.reload());
      }
    } else if (upload['upload']) {
      setUpload({upload: false, soldOut: false})
    }
  }, [upload, date]);

  useEffect(() => {
    setDate(wednesdays[0])
  }, [wednesdays]);

  useEffect(() => {
    if (wednesdays.length === 0) {
      getNext10WednesdaysWithSoldOut().then(newWeds => {
        setWednesdays(newWeds);
      })
    }
  }, [wednesdays]);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(user);
    } else {
      navigate('/admin')
    }
  });

  return (
    <>
      {user === null ? <div></div> : <div>
        <h1 className={'adminHeader'}>UPDATE QUIZ</h1>
        <p>Select a date, then press "SUBMIT SOLD OUT" to confirm it is sold out, or "UN-SUBMIT" if something changes.</p>
        <div className="updateQuizInnerContainer">
          <select id={'wednesdays'} onChange={(event) => {
            setDate(event.target.value);
          }} value={date}>
            {wednesdays.map((date, index) => (
              <option key={index} value={date}>
                {date}
              </option>
            ))}
          </select>
          <button className={'adminBtn'} onClick={() => {
            setUpload({upload: true, soldOut: true})
          }}>SUBMIT SOLD OUT
          </button>
          <button className={'adminBtn'} style={{backgroundColor: 'red'}} onClick={() => {
            setUpload({upload: true, soldOut: false})
          }}>UN-SUBMIT SOLD OUT
          </button>
        </div>
      </div>}
    </>
  )
}

export default UpdateQuiz;