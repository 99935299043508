// React
import {useState} from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Stylesheets
import './App.css';
import './css/Anim.css';
import './fonts/index.css';

// Components
import { Home, Terms, Privacy } from './pages'
import { Admin, UpdateBeers, UpdateEvents, UpdateHours, UpdateQuiz, UpdateHeroImages } from './pages/admin'
import QuizTables from "./pages/admin/quizTables/QuizTables";
import UpdateBooking from "./pages/updateBooking/UpdateBooking";


function App() {
  let [currentUser, setCurrentUser] = useState(null);
  const [quizOpen, setQuizOpen] = useState(false)

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path={"/"} element={<Home quizOpen={quizOpen} setQuizOpen={setQuizOpen} />} />
          <Route exact path={"/*"} element={<Home quizOpen={quizOpen} setQuizOpen={setQuizOpen} />} />
          <Route exact path={"/quiz"} element={<Home quizOpen={quizOpen} setQuizOpen={setQuizOpen} showQuiz={true} />} />
          <Route exact path={"/terms"} element={<Terms />} />
          <Route exact path={"/privacy"} element={<Privacy />} />
          <Route exact path={"/admin"} element={<Admin user={currentUser} setUser={setCurrentUser} />} />
          <Route exact path={"/admin/beers"} element={<UpdateBeers user={currentUser} setUser={setCurrentUser} />} />
          <Route exact path={"/admin/events"} element={<UpdateEvents user={currentUser} setUser={setCurrentUser} />} />
          <Route exact path={"/admin/hours"} element={<UpdateHours user={currentUser} setUser={setCurrentUser} />} />
          <Route exact path={"/admin/hero"} element={<UpdateHeroImages user={currentUser} setUser={setCurrentUser} />} />
          <Route exact path={"/admin/quiz"} element={<UpdateQuiz user={currentUser} setUser={setCurrentUser} />} />
          <Route exact path={"/admin/tables"} element={<QuizTables user={currentUser} setUser={setCurrentUser} />} />
          <Route exact path={"/booking/:bookingId/:email?"} element={<UpdateBooking user={currentUser} setUser={setCurrentUser} quizOpen={quizOpen} setQuizOpen={setQuizOpen} />} />
        </Routes>
      </Router>
    </div>
    );
  }

  export default App;
